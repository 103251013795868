@import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.italiana-regular {
  font-family: "Italiana", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.inria-sans-light {
  font-family: "Inria Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.inria-sans-regular {
  font-family: "Inria Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.inria-sans-bold {
  font-family: "Inria Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.inria-sans-light-italic {
  font-family: "Inria Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.inria-sans-regular-italic {
  font-family: "Inria Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.inria-sans-bold-italic {
  font-family: "Inria Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}



body {
  margin: 0;
  font-family: 'Inria Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color: black !important;
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img{
  width: auto;
  height: 100%;
}

li{
  list-style-type: none;
}

nav {
  backdrop-filter: blur(10px) !important; /* The blur effect */
  -webkit-backdrop-filter: blur(10px) !important; /* For Safari */
  
  background: rgba(180, 180, 180, 0.4);
  
  position: fixed !important;
  width: 100%;

  z-index: 999;
} 

.navbar-brand img{
  width: 55px;
}

.switchers li{
  text-decoration: none;
  list-style-type: none;
  display: inline-block;
}

#navbarSupportedContent{
  justify-content: right;
}

.navbar-nav{
  margin: 0px 20px;
  font-weight: bold;
}

.navbar-nav li{
  margin: 0px 30px;
}

.navbar-nav li a{
  color: white !important;
}

.navbar-nav li::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  position: relative;
  background: #E8A848;
  top: -5px;
  left: 8px;
}

.lang-select{
  background: #E8A848;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: bold;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.main-screen{
  height: 100vh;
  position: relative;
}

.main-screen-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/main-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.18);
  z-index: -1;
}
  

.center-heading{
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  height: 300px;
  padding-top: calc(50vh - 150px - 51px);
}

.main-heading{
  color: white;
  font-size: 140px;
  text-align: center;
}

.main-heading-2{
  font-size: 130px;
  text-align: center;
  font-family: "Inria Sans", sans-serif;
  font-weight: normal;
  color: #E8A848;
  margin-top: -30px;
}

.gold{
  color: #E8A848;
}

.main-heading-3{
  margin-top: -10px;
  font-size: 85px;
  font-family: "Inria Sans", sans-serif;
  font-weight: normal;
  color: white;
  text-align: center;
}

.trusted-by{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.trusted-by img{
  width: 80%;
}

.nav-link{
  text-transform: uppercase;
}

.light-text{
  font-weight: 300 !important;
}

.trusted{
  min-width: 120px;
  margin-right: 100px;
  color: white;
  font-weight: bold;
  font-family: "Inria Sans";
  font-size: 22px;
}

.popup{
  padding: 20px;
}

.trusted-container{
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  bottom: 20px;
  margin-left: auto;
  margin-right: auto;

  width: auto;
  max-width: 1320px;
}

.flex-links{
  position: relative;
  top: 16px;
}

.flex-links-container{
  margin-left: 40px;
}

.flex-links a{
  display: inline-block;
  width: 100%;
}

.flex-links img{
  width: 100px;
  height: auto;
}



.flex-links a:nth-child(2) img{
  width: 200px !important;
  height: auto;
  position: relative;
  top: -16px;
}

@media screen and (max-width: 1233px) {
  .flex-links a:nth-child(2) img {
      width: 140px !important;      
  }
  .flex-links img{
    width: 88px;
  }
}

.flex-links-container{
  display: flex;
  flex-direction: column;
  min-width: 40%;
  text-transform: uppercase;
  position: relative;
  top: 10px;
}

.career-block .flex-items{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.video-comp{
  background: black;
  min-height: 600px;
}

.video-container video{
  width: 100%;
  height: auto;
  border: 5px solid white;
}

.video-comp h1{
  padding-top: 70px;
  margin-bottom: 50px;
  color: white;
  font-family: 'Inria Sans';
  font-size: 50px;
  font-weight: bold;
}

.video-container{
  position: relative;
  padding-bottom: 70px;
}

.video-comp h1::after{
  content: "";
  display: block;
  position: relative;
  width: 100px;
  height: 3px;
  background: #E8A848;
}

.play-button{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  background: url('/src/assets/play.png') no-repeat center center;
  border-radius: 60px;
  background-size: contain;
  cursor: pointer;
  z-index: 990;
  opacity: 1;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.to-link {
  color: white;
  width: fit-content;
  margin-top: 20px;
  margin-left: 25px;
  font-size: 22px;
  font-weight: lighter;
  transition: 0.5s ease-in-out;
}

.to-link:hover{
  font-weight: normal;
}

.scrolling-container{
  background: #E8A848;
  color: white;
  width: 100%;
  overflow: hidden;
}

.scrolling-text{
  width: max-content;
  padding: 1em 0;
  display: flex;
  flex-wrap: nowrap;
  gap: 1em;
  list-style: none;
  animation: move 20s linear infinite;
  text-transform: capitalize;
}

@keyframes move {
  to {
    transform: translate(calc(-50% - 0.5em));
  }
}

.about{
  margin: 50px 0px;
}

.right-side-about h1{
  font-family: 'Inria Sans';
  font-size: 50px;
  font-weight: bold;
}

.right-side-about h1::after{
  content: "";
  display: block;
  position: relative;
  width: 100px;
  height: 3px;
  background: #E8A848;
}

.right-side-about p{
  font-size: 20px;
  margin-top: 40px;
}

.left-side-about img{
  width: 100%;
}

.about .container .row{
  align-items: center;
}

.our-mission h1::after {
  top: 2px;
  content: "";
  text-align: center;
  display: block;
  position: relative;
  width: 100px;
  height: 3px;
  background: #E8A848;
  left: 100px;
}

.our-mission{
  text-align: center;
  margin-top: 80px;
}

.our-mission h1{
  width: 300px;
  font-family: 'Inria Sans';
  font-size: 50px;
  font-weight: bold;

  margin-left: auto;
  margin-right: auto;
}

.mission-desc{
  padding: 30px;
  width: 850px;
  min-height: 200px;
  border: 5px solid black;
  font-size: 22px;

  margin-left: auto;
  margin-right: auto;

  margin-top: 30px;
}

.career h1{
  text-transform: uppercase;
  margin-top: 80px;
  font-weight: bold;
  font-size: 40px;
}

.career-points{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0 !important;
}

.career-points li{
  min-width: 300px;
  font-size: 22px;
  margin-right: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Inria Sans", sans-serif;
}

.career h1{
  text-align: center;
  font-family: "Inria Sans";
}

.career h2 {
  font-family: "Italiana";
  flex: 1 1;
  font-size: 50px;
}

.our-mission h1{
  text-transform: uppercase;
}

.career-points li::before {
  content: "";
  width: 30px;
  height: 30px;
  background: #E8A848;
  border-radius: 20px;
  display: inline-flex;
  position: relative;
  top: 7px;
  right: 10px;
}

.career h1::after {
  top: 2px;
  content: "";
  text-align: center;
  display: block;
  position: relative;
  width: 100px;
  height: 3px;
  background: #E8A848;
  margin-left: auto;
  margin-right: auto;
}

.flex{
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 80px;
}

.career-block{
  position: relative;
  min-height: 400px;
  color: white;
}

.career-block h1{
  font-size: 110px;
  font-family: "Italiana", sans-serif;
  padding-top: 40px;
  padding-bottom: 10px;
}

.career-screen-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/career-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.18);
  z-index: -1;
}

.btn-form{
  background: #E8A848;
  color: white;
  border: none;
  font-family: "Inria Sans", sans-serif;
  font-size: 22px;
  width: 200px;
  height: 60px;
}

.career-block .flex{
  align-items: center;
  justify-content: space-between;
}

.career-block{
  padding-bottom: 1px;
}

.contacts{
  padding-top: 50px;
  background: #fff;
}

.contacts .flex{
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 20px;
}

.contacts h1{
  font-size: 50px;
  font-family: "Inria Sans", sans-serif;
  font-weight: bold;
}

.contacts h1::after {
  top: 2px;
  content: "";
  text-align: center;
  display: block;
  position: relative;
  width: 100px;
  height: 3px;
  background: #E8A848;
}

.arrow{
  width: 18px;
  height: 15px;
  background: url("/src/assets/arrow.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-left: 5px;
  margin-right: 10px;
}

.light-text{
  display: inline-block;
  font-weight: 300;
}

.contact-info{
  margin: 50px 0px;
}

.location{
  margin-top: -20px;
}

.socials ul{
  display: flex;
  width: 314px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.socials li{ 
  height: 120px;
  margin: 10px;
  width: 120px;
}

.socials li div{
  height: 100%;
} 

.socials li img{
  width: auto;
  height: 15px;
  transform: translate(calc(120px/2 - 6.5px), calc(120px/2 - 15px));
}

.soc-fb{
  background: #567AFB;
}

.soc-inst{
  background: rgb(241,36,200);
  background: linear-gradient(153deg, rgba(241,36,200,1) 0%, rgba(255,139,0,1) 81%);
}

.soc-x{
  background: #0BC4FF;
}

.soc-tt{
  background: #000000;
}

footer{
  border-top: 1px solid #E8A848;
  padding: 10px 0 0px;
  font-family: "Inria Sans", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.popup-overlay{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.popup{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  height: fit-content;
  width: fit-content;
  background: white;
  z-index: 1001;
  margin: 50px;
  margin-left: auto;
  margin-right: auto;
}

.popup h2{
  text-align: center;
  font-size: 40px;
  margin-top: 40px;
  padding: 0px 40px;
}

.form{
  font-family: "Inria Sans", sans-serif;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.form input{
  padding: 5px;
  border: 3px solid black;
  font-family: "Inria Sans", sans-serif;
  width: calc(100%);
  font-weight: bold;
  margin: 10px 0px;
}

.form textarea{
  padding: 5px;
  border: 3px solid black;
  font-family: "Inria Sans", sans-serif;
  resize: none;
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  font-weight: bold;
  margin: 20px 0px;
}

.form h5{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
}

.fieldset{
  max-width: 400px;
}

input[type="radio"]{
  width: 10px;
  margin-right: 10px;
}

.form-apply{
  background: #E8A848;
  color: white;
  border: none;
  font-family: "Inria Sans", sans-serif;
  font-size: 22px;
  width: 200px;
  height: 60px;

  margin-top: 20px;
}

.form{
  margin-bottom: 20px;
}

.upload-input{
  font-weight: bold;
  margin-top: 20px;
}

.birth-label{
  position: relative;
  top: 18px;
}

.birth-input{
  width: auto !important;
  float: right !important;
}

.navbar-toggler{
  background: white !important;
  border: none !important;
}

.career-block p{
  margin-bottom: 0 !important;
}

.popup-special p{
  text-align: center;
}

@media screen and (max-width: 1210px) {
  .career-block h1 {
    font-size: 80px;
  }
  .popup {
    max-height: 90vh;
    overflow-y: scroll;
  }
  .flex-links-container h2{
    font-size: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .career-block h1 {
    font-size: 80px;
  }
  .career h2{
    font-size: 50px !important;
    font-weight: bold;
  }
  .career-points {
    width: 400px;
  }
}

@media screen and (max-width: 999px) {
  .navbar-nav{
    display: flex !important;
    flex-direction: row !important;
  }

  #navbarSupportedContent{
    justify-content: center;
  }

  .navbar-nav{
    margin-left: 0 !important;
  }

  .mission-desc{
    width: auto !important;
    font-size: 20px;
  }
  
  .career-block h1 {
    font-size: 70px;
  }

  .career-block .flex {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .career-block{
    min-height: 300px;
  }
  .play-button {
    transform: translate(-50%, -82%);
  }
  .to-link{
    font-size: 14px;
  }
  .flex-links-container h2 {
    font-size: 22px;
  }
  .flex-links-container {
    margin-left: 0px;
    margin-top: 10px;
  }
  .flex-links img {
    width: 70px;
  }
  .flex-links a:nth-child(2) img {
    width: 100px !important;
  }

  .flex-links p {
    margin-bottom: 0 !important;
    display: inline-block;
    margin-left: 20px;
  }
}


@media screen and (max-width: 899px) {
  .main-heading{
    font-size: 110px;
  }
  .main-heading-2{
    font-size: 113px;
  }
  .main-heading-3{
    font-size: 72px;
  }
  .center-heading {
    padding-top: calc(50vh - 150px - 16px);
  }
  .trusted {
    margin-right: 60px;
  }
  .trusted-by li:last-child img{
    position: relative;
    right: 30px;
  }
}

@media screen and (max-width: 799px) {
  .main-heading{
    font-size: 85px;
  }
  .main-heading-2{
    font-size: 88px;
  }
  .main-heading-3{
    font-size: 56px;
    margin-top: -14px;
  }
  .center-heading {
    padding-top: calc(50vh - 150px + 5px);
  }
  .career-points li {
    min-width: 240px !important;
  }
  .career-block h1{
    font-size: 70px !important;
  }
  .career h1 {
    font-size: 37px;
  }
  .career h2 {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 767px) {
  .right-side-about p {
    margin-bottom: 45px;
  }
  .left-side-about{
    text-align: center;
  }
  .left-side-about img{
    width: 70%;
  }
  .career-block h2{
    font-size: 23px;
  }
  .video-comp h1 {
    font-size: 45px !important;
  }
  .popup{
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .flex-links-container{
    display: none;
  }
  .dp-flex-onsmall{
    display: flex !important;
    margin-bottom: 60px;
    position: relative;
    top: 8px;
    justify-content: center;
    align-items: center;
  }
}

.dp-flex-onsmall{
  display: none;
}

@media screen and (max-width: 699px) {
  .career h2 {
    font-size: 40px !important;
  }
  .trusted-by li:last-child img {
    position: relative;
    right: 24px;
  }
  .navbar-nav{
    display: block !important;
  }
  #navbarSupportedContent{
    flex-direction: column;
  }
  .navbar-nav{
    margin: 0 !important
  }
  .nav-item{
    width: fit-content;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .lang-select{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .navbar-nav li::after {
    left: calc(50% - 15px);
  }
}

@media screen and (max-width: 580px) {
  .career .flex {
    flex-direction: column;
  }
  .career h1 {
    font-size: 32px;
    text-wrap: pretty;
  }
  .socials ul {
    display: flex;
    width: auto;
  }
  .career h2 {
    text-align: center;
    font-size: 25px !important;
    margin-bottom: 30px;
  }
  .video-comp h1, .right-side-about h1, .our-mission h1 {
    font-size: 32px;
  }
  .career-block .flex {
    flex-direction: column;
    text-align: center !important;
  }
  .career-block h1{
    text-align: center;
  }
  .career-block .flex div:first-child{
    margin-bottom: 20px;
  }
  .trusted-by li:last-child img {
    position: relative;
    right: 20px;
  }
  .trusted {
    margin-right: 20px;
  }
  .career-block .flex {
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .play-button {
    transform: translate(-50%, -100%);
    width: 70px;
    height: 70px;
  }
  
}

@media screen and (max-width: 470px) {
  .main-heading {
    font-size: 60px;
  }
  .main-heading-2 {
    font-size: 62px;
    margin-top: -22px;
  }
  .main-heading-3 {
    font-size: 39px;
    margin-top: -14px;
  }
  .center-heading {
    padding-top: calc(50vh - 150px + 66px);
  }
  .video-comp {
    background: black;
    min-height: 484px;
  }
  .right-side-about p {
    font-size: 18px;
    margin-top: 40px;
  }
  .mission-desc {
    font-size: 18px;
    text-wrap: pretty;
  }
  .career-block h1 {
    font-size: 52px !important;
  }
  .contacts .flex{
    flex-direction: column;
  }
  .socials .flex{
    flex-direction: row;
  }
  .socials ul {
    width: 314px;
  }
  .career-points {
    width: auto;
  }
  footer{
    font-size: 14px;
  }
  .trusted-container{
    flex-direction: column;
  }
  .trusted-by img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: block;
  }
  .trusted-by li:last-child img {
    position: relative;
    right: 0;
  }
  .socials ul{
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 399px) {
  .video-comp h1{
    text-wrap: pretty;
  }
}